import axios from './axios';
import axiosFileDownloader from './axiosFileDownloader';
import queryString from 'query-string';

export default class OrderService {

    getNotes(ordNum) {
        return axios.post('api/order/notes/list?ordNum=' + ordNum);
    }

    AddDebtorsInvoice_Notes(Notes, OrdNum, UserName) {
        return axios.post('api/order/notes/add?Notes=' + Notes + '&OrdNum=' + OrdNum + '&UserName=' + UserName);
    }

    getOrder(ordNum, includeOrderItems, isShowOnInvoiceOrderItems, includeOrderItemProductLabels, navigation) {
        let request = {
            ordNum, includeOrderItems, isShowOnInvoiceOrderItems, includeOrderItemProductLabels, navigation
        };
        return axios.post('api/order/one?' + queryString.stringify(request), {}, {
            timeout: 120 * 1000
        });
    }

    getOrderPartList(ordNum, itemNum) {
        let request = {ordNum};
        if (itemNum) {
            request.itemNum = itemNum;
        }
        return axios.post('api/order/one/part-list?' + queryString.stringify(request));
    }

    getOrderCostByOrderNo(OrderNo, Discount) {
        return axios.post('api/order/items/list/cost?orderNo=' + OrderNo + '&discount=' + Discount)
    }

    GenerateDebtorOrderReport(OrderNo) {
        return axiosFileDownloader.post('api/order/OrderDetailReport?OrderNo=' + OrderNo);
    }

    GenerateDebtorOrderSummaryReport(OrderNo) {
        return axiosFileDownloader.post('api/order/OrderSummaryReport?OrderNo=' + OrderNo);
    }

    GenerateDebtorStatementInExcelReport(id, yearmonth) {
        return axiosFileDownloader.post('api/customer/statement/export/excel/download?AccountId=' + id + '&YearMonth=' + yearmonth);
    }

    updateRequiredDate(orderNum, requiredDate) {
        return axios.put('api/sales/orders/update/requiredDate?orderNum=' + orderNum + '&requiredDate=' + requiredDate);
    }

    updateProductionReadyDate(orderNum, productionReadyDate) {
        return axios.put('api/sales/orders/update/productionReadyDate?orderNum=' + orderNum + '&productionReadyDate=' + productionReadyDate);
    }

    updateNote(request) {
        return axios.put('api/sales/orders/update/note', request);
    }

    updateCustomerOrderNumber(request) {
        return axios.put('api/sales/orders/update/customerOrderNumber', request);
    }

    SendStatementInEmail(requestBody) {
        return axios.post('api/customer/statement/export/pdf/email', requestBody);
    }

    getAllGatewayOrders(requestBody, type) {
        return axios.post('/api/order/gateway?type=' + type, requestBody)
    }

    getAllKeywayOrders(requestBody, type) {
        return axios.post('/api/order/gateway/keyway/salesorders?type=' + type, requestBody)
    }


    getOrderDebtorCategoryDetails(OrderNumbersRequest) {
        return axios.post('/api/order/debtor/category/details', OrderNumbersRequest)
    }


    updateGatewayOrders(ordNums, type) {
        return axios.post('/api/order/status/update?status=' + type + '&ordNums=' + ordNums)
    }

    getOrderStatuses() {
        return axios.get('api/order/status/get');
    }

    getSubmittedOrdersSummary(request) {
        return axios.post('/api/order/gateway/orders/summary', request);
    }

    getSubmittedOrdersDebtorSummary(request) {
        return axios.post('/api/order/gateway/orders/debtor/summary', request);
    }

    downloadInvoice(OrderNo) {
        return axiosFileDownloader.post('api/customer/invoice/download?OrderNo=' + OrderNo);
    }

    downloadFile(type, OrderNo) {
        switch (type) {
            case "Pick Slip":
            case "Job Sheet":
            case "Production Label":
            case "Invoice":
            case "Quote":
            case "Dispatch Label":
            case "Packing Slip":
            case "Sales Order Summary":
            case "Consign Label":
            default:
                return axiosFileDownloader.post('api/order/file/download?type=' + type + "&OrderNo=" + OrderNo);
        }
    }

    printFile(type, ordNum) {
        return axios.get('/api/order/file/print?ordNum=' + ordNum + '&type=' + type);
    }

    getCountOfOrder(type, branch) {
        return axios.get('/api/order/gateway/count?type=' + type + '&branch=' + branch)
    }

    getCountOfOrderByStatus(req) {
        return axios.post('/api/order/gateway/count/by-status', req);
    }

    getOrderSummaryForOpeningSpecificTab(Ordnum) {
        return axios.get('/api/order/gateway/tab/details?Ordnum=' + Ordnum);
    }

    getOrderRelatedDetails(Ordnum) {
        return axios.get('/api/order/gateway/details?Ordnum=' + Ordnum);
    }

    exportAllGatewayOrders(requestBody, type) {
        return axiosFileDownloader.post('/api/order/gateway/export?type=' + type, requestBody)
    }

    changeOrderStatusForMultipleOrders(req) {
        return axios.post('/api/order/orderNumbers/change/status', req);
    }

    getCustomerOrderDetails(req) {
        return axios.post('/api/order/customer/details', req);
    }

    downloadDailySummaryReport(fromDateString, toDateString) {
        return axiosFileDownloader.post('api/order/download/salesSummary?fromDateString=' + fromDateString + '&toDateString=' + toDateString);
    }

    SendInvoiceInEmail(requestBody) {
        return axios.post('api/customer/invoice/sendEmail', requestBody);
    }


    updateSalesOrderDiscount(request) {
        return axios.put('api/order/discount/update', request);
    }

    getDebtorInvoiceItems(req) {
        return axios.post('api/order/invoice/items/list', req, {
            timeout: 60000 
        });
    }


    getOrderStatus(OrderNo) {
        return axios.get('api/order/status?OrderNo=' + OrderNo);
    }

    isOrderDescheduled(OrdNum) {
        return axios.get('api/production/descheduled/order?OrdNum=' + OrdNum);
    }

    isCutJobStarted(OrdNum) {
        return axios.get('api/production/check/cutJob?OrdNum=' + OrdNum);
    }

    checkIsPowderCoatOrder(OrderNo) {
        return axios.get('api/order/checkIfPowderCoatOrder?OrderNo=' + OrderNo);
    }

    getStockInfoByProdcodes(req) {
        return axios.post('api/production/enquiry/order/item/stock/info', req);
    }

    shareDebtorInvoiceOnEmail(ordNum, request) {
        return axios.post("api/order/share/debtor/email?ordNum=" + ordNum, request);
    }

    updateOrderOverrideHold(ordNum, value) {
        return axios.post("/api/order/update/order/overrideHold?ordNum=" + ordNum + "&overrideHoldValue=" + value);

    }

    updateOrderDeliveryAddress(request, ordNum) {
        return axios.post("/api/order/update/order/deliveryAddress?ordNum=" + ordNum, request);
    }

    downloadGenerateDeliverySheet(ordNums, driverName) {
        return axiosFileDownloader.post('/api/dispatch-order/generate/deliverySheet/download?ordNums=' + ordNums + '&driverName=' + driverName);
    }

    generateSalesOrderExcelReport(request) {
        return axiosFileDownloader.post('api/order/generate/summary/excel', request);
    }

    saveReworkOrderDetailsPre(request) {
        return axios.post('api/rework/order/save/warranty/claim/details/pre', request);
    }

    saveReworkOrderDetailsPost(request) {
        return axios.post('api/rework/order/save/warranty/claim/details/post?' + queryString.stringify(request));
    }

    updateReworkOrderDetails(request) {
        return axios.post('api/rework/order/update/warranty/claim/details', request);
    }

    getReworkDetailList(request) {
        return axios.post("api/rework/order/rework/details/list", request);
    }

    getReworkDetailListFromOriginalOrdNum(originalOrdNum) {
        return axios.get("api/rework/get/rework/order/list?originalOrdNum=" + originalOrdNum);
    }

    warrantyClaimFormEmail(request) {
        return axios.post("api/rework/send/claim/email", request);
    }

    getDebtorInvoiceData(ordNum) {
        return axios.get("api/rework/get/debtor/invoice/details?ordNum=" + ordNum);
    }

    prepareReworkOrderData(request) {
        return axios.post("api/rework/prepare/rework/order", request);
    }

    getPBReworkOrderData() {
        return axios.get('api/rework/product-builder/rework/order/summary/weekly/data');
    }

    getReworkOrderDetail(newOrdNum) {
        return axios.get("api/rework/get/rework/order?newOrdNum=" + newOrdNum);
    }

    approveReworkOrder(request, type) {
        return axios.post("api/rework/approve/rework/order?type=" + type, request);
    }

    getOrderDetails(ordNum) {
        return axios.post("api/rework/get/previous/order/details?ordNum=" + ordNum);
    }

    exportAllReworkOrders(requestBody) {
        return axiosFileDownloader.post('/api/rework/order/report/export', requestBody)
    }

    getReworkOrderTypes() {
        return axios.get("api/rework/type/list");
    }

    getReworkOrderSummaryBoard() {
        return axios.get("api/rework/order/summary/board");
    }

    getReworkOrderReasonWiseData() {
        return axios.get("api/rework/order/reason/wise/data");
    }

    getReworkOrderList(request) {
        return axios.post("api/rework/order/rework/list", request);
    }

    updateReworkPriceWaivedItem(discount, ordNum) {
        return axios.post("api/rework/update/waived/order/item?ordNum=" + ordNum + "&discount=" + discount, null);
    }

    copyOrder(request) {
        return axios.post('api/sales/order/product-builder/v1/order/one/copy?', request, {
            timeout: (360 * 1000)
        });
    }

    getQuoteStatus(ordNum) {
        return axios.get('/api/order/quote/isValid?ordNum=' + ordNum);
    }

    updatePickedItemsDetails(request) {
        return axios.put("api/order/update/pickedItems", request);
    }

    getSalesOrderJobDetails(ordNum, productionLineID) {
        return axios.get('/api/order/get/salesOrderJobDetails?ordNum=' + ordNum + '&productionLineID=' + productionLineID);
    }

    getImportSalesOrderStatuses(request) {
        return axios.post("api/order/get/importSalesOrderStatuses", request);
    }

    getImportSalesOrderSources() {
        return axios.get("api/order/get/importSalesOrderSources");
    }

    getImportSourceOrderIDs() {
        return axios.get("api/order/get/importSourceOrderIDs");
    }

    clearAndReImportSalesOrder(ordNum) {
        return axios.get("api/order/clearAndReImportSalesOrder?ordNum=" + ordNum);
    }

    clearImportedItems(ordNum) {
        return axios.get("api/order/clearImportedItems?ordNum=" + ordNum);
    }

    searchJobRef(inputValue) {
        return axios.get("api/order/searchJobRef?jobRef=" + inputValue);
    }

    searchCustOrdNum(inputValue) {
        return axios.get("api/order/searchCustOrdNum?custOrdNum=" + inputValue);
    }

    searchImportSourceOrderID(inputValue) {
        return axios.get("api/order/searchImportSourceOrderID?importSourceOrderID=" + inputValue);
    }

    getOOSDetails(ordNum) {
        return axios.get("api/production/orders/getOOSDetails?ordNum=" + ordNum);
    }

    exportReworkReport(requestBody) {
        return axiosFileDownloader.post("api/rework/generate/summary/excel", requestBody);
    }

}